/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { forwardRef, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogTitle, Grid, Slide } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import DialogContent from '@material-ui/core/DialogContent';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import { Form } from '../Common/styled/Form';
import Loading from '../Common/Loading';
import { INVITE_PATIENT } from '../../graphql/mutations';
import { TextFieldUI } from '../../componentsUI/TextField';
import { AlertUI } from '../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  form: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: fade(theme.palette.text.primary, 0.54),
  },
  submit: {
    minWidth: 200,
    float: 'right',
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const InvitePatientOptionDialog = ({ open, children, patient, onClose, setHasEmail, setIsInvited }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const classes = useStyles();
  const language = i18n && i18n.language;

  const [invitePatient, { loading }] = useMutation(INVITE_PATIENT, {
    onCompleted() {
      toast(t('patient.has.received.invitation'), { className: 'toast-success' });
      if (setIsInvited) setIsInvited(true);
      if (setHasEmail) setHasEmail(true);
      else onClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      const warning = error.graphQLErrors ? error.graphQLErrors[0].message : t('invitation.error');
      setServerError(warning);
    },
  });

  const closeModal = () => {
    setServerError(null);
    if (!loading) onClose();
  };

  const validationSchema = Yup.object().shape({
    userEmail: Yup.string()
      .required(t('required.field'))
      .email(t('invalid.email.address')),
  });

  const initialValues = {
    userEmail: patient.email || '',
  };

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    setSubmitDisabled(true);
    try {
      await invitePatient({
        variables: {
          ...values,
          language,
          patientUuid: patient.uuid,
        },
      });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
    >
      <DialogTitle>{t('invite.patient')}</DialogTitle>
      <DialogContent>
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(props) => (
            <Form autoComplete="off" id="invitePatient" onSubmit={props.handleSubmit}>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={12}>
                  <TextFieldUI name="userEmail" label={t('email')} props={props} classes={classes} disabled={submitDisabled} />
                  {children}
                </Grid>
                <Grid item xs={12}>
                  {loading && <Loading />}
                  {serverError && (
                    <AlertUI severity="error" title="Error">
                      {t('server.error')}
                    </AlertUI>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          form="invitePatient"
          type="submit"
        >
          {t('invite')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
