import React, { forwardRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, Slide } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

import { NewShare } from '../NewShare';
import { ManageCollaborationPermissions } from '../ManageCollaborationPermissions';
import { isEmpty } from '../../../utils/ObjectUtils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 960,
      height: '100vh',
      padding: 16,
      '& .MuiDialogTitle-root .MuiTypography-root': {
        fontWeight: 600,
      },
      '@media (max-width:991px)': {
        padding: 12,
        margin: 24,
      },
      '@media (max-width:599px)': {
        padding: 6,
        margin: 12,
        '& .MuiDialogTitle-root': {
          padding: '8px 12px',
        },
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '@media (max-width:991px)': {
      padding: 12,
    },
    '@media (max-width:599px)': {
      padding: 6,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: fade(theme.palette.text.primary, 0.54),
  },
  submit: {
    minWidth: 200,
    float: 'right',
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const ShareCaseDialog = ({ medicalCase, open, onClose, refetch }) => {
  if (!medicalCase) return null;

  const { t } = useTranslation();
  const { collaborations, uuid } = medicalCase;
  const sharedWith = [...collaborations, ...medicalCase.groupCollaborations];
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('share.settings')}</DialogTitle>
      <DialogContent className={classes.content}>
        {!isEmpty(medicalCase) && (
          <NewShare
            medicalCase={medicalCase}
            refetch={refetch}
            onClose={onClose}
          />
        )}
        {
          !!sharedWith.length && (
            <ManageCollaborationPermissions
              noBorder
              collaborations={sharedWith}
              medicalCaseUuid={uuid}
            />
          )
        }
        <div style={classes.expand} />
      </DialogContent>
    </Dialog>
  );
};
